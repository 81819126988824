import { defineStore } from 'pinia'

export const useCurrentRoleStore = defineStore('currentRoleStore', {
  state: () => ({
    currentRole: {} as SchoolRole,
  }),
  getters: {
    getCurrentRole: (state) => {
      return () => state.currentRole
    },
  },
  actions: {
    setCurrentRole(role: SchoolRole) {
      this.currentRole = role
    },
  },
  persist: true,
})
